import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';

import { ComponentsModule } from './components/components.module';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptor, HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './security/interceptors/HttpConfigInterceptor';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../environments/environment"

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AgmCoreModule } from '@agm/core';

import { RecaptchaModule } from 'ng-recaptcha';
import { HighchartsChartModule } from 'highcharts-angular';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.social.GOOGLE_ID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.social.FACEBOOK_ID)//("313635033369228")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    //MDBBootstrapModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ArchwizardModule,
    NgxSpinnerModule,
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKeyGoogle
    }),
    RecaptchaModule,
    HighchartsChartModule
    //NgBootstrapFormValidationModule.forRoot()
    //MDBBootstrapModule.forRoot(),
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpConfigInterceptor, 
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  /*constructor(public matIconRegistry: MatIconRegistry) {
      matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
  }*/
}
