export enum StorageKeyEnum {
	JWT_AUTHORIZATION = 'JWTAuthorization',
	UsuarioID = 'UsuarioId',
	Email = 'Email',
	Login = 'Login',
	Nombre = 'Nombre',
	DocIdentidad = 'DocIdentidad',
	Expiration = 'Expiration',
	Loocked = 'Loocked',
	Type = 'Type',
	Empresa = 'Empresa'
}