import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators'
import { HeaderBasicAuthorizationService } from '../../_shared/services/header-basic-authorization.service';
import { JwtService } from '../../_shared/services/jwt.service';
import { StorageService } from '../../_shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { ApiEnum } from '../../_shared/enums/api.enum';
import { StorageKeyEnum } from '../../_shared/enums/storage-key.enum';
import { PermissionNavigationService } from '../../_shared/services/permission-navigation.service';
import { throwError } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    numberDetails = 0;
    isLoggedSocial = false;
    centroSelected = 0;

    constructor(private router: Router,
        private storageService: StorageService,
        private jwtService: JwtService,
        private httpClient: HttpClient,
        private headerBasicAuthorization: HeaderBasicAuthorizationService,
        private permissionNavigationService: PermissionNavigationService) { }

    // login(login: string, password: string, tipoUsuario: boolean, Centro: number) {
    //     return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ZITG_Login`, 
    //         {
    //             login: login,
    //             password: password,
    //             trequest: tipoUsuario,
    //             prmintCentro: Centro
    //         }, {
    //             headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
    //         }
    //     )
    //         .pipe(
    //             tap((response: any) => {
    //                 if (response.Ok) {
    //                     this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
    //                     this.jwtService.load(response.JWT);
    //                 }
    //             })
    //         )
    // }

    login(login: string, password: string, sociedad: number) {
		return this.httpClient
			.post(
				`${environment.api.WS_IT.url}/Usuario/loginNo2F`,
				{
					userName: login,
					password: password,
					sociedad: sociedad
				},
				{}
			)
			.pipe(
				map((response: any) => {
					if (response.response) {
						this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.token);
						this.storageService.set(StorageKeyEnum.UsuarioID, response.usuarioID);
						this.storageService.set(StorageKeyEnum.Nombre, response.nombreCompleto);
						this.storageService.set(StorageKeyEnum.Email, response.email);
						this.storageService.set(StorageKeyEnum.Login, response.username);
						this.storageService.set(StorageKeyEnum.DocIdentidad, response.DocIdentidad);
						this.storageService.set(StorageKeyEnum.Expiration, response.expiration);
						this.storageService.set(StorageKeyEnum.Loocked, response.loocked);
						this.storageService.set(StorageKeyEnum.Type, response.type);
						this.storageService.set(StorageKeyEnum.Empresa, response.empresaId);
						this.jwtService.load(response.token);
					}
					return response;
				}),
				catchError((error, caught) => {
					return throwError(error);
				})
			);
	}

    loginSocial(TipoSocial: string, SocialId: string, Email: string, Nombre: string, Apellidos: string, UrlPhoto: string, Latitud: string, Longitud: string, Centro: Number) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ZITG_LoginSocial`, 
            {
                prmstrTipoSocial: TipoSocial,
                prmstrSocialId: SocialId,
                prmstrEmail: Email,
                prmstrNombre: Nombre,
                prmstrApellidos: Apellidos,
                prmstrImage: UrlPhoto,
                prmstrLat: Latitud,
                prmstrLong: Longitud,
                prmintCentro: Centro
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
            .pipe(
                tap((response: any) => {
                    if (response.Ok) {
                        this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                        this.jwtService.load(response.JWT);
                    }
                })
            )
    }

    logoff() {
        this.permissionNavigationService.reset();
        this.storageService.remove(StorageKeyEnum.JWT_AUTHORIZATION);
        this.jwtService.clear();
    }

    refreshToken() {
        return this.httpClient.get(`${environment.api.WS_IT.url}/Usuario/RefreshToken`, {
            headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
        }).pipe(
            tap((response: any) => {
                if (response.Ok) {
                    this.storageService.set(StorageKeyEnum.JWT_AUTHORIZATION, response.JWT);
                    this.jwtService.load(response.JWT);
                }
            })
        );
    }

    getCategorias(Centro) {
		return this.httpClient.get(`${environment.api.WS_ECOMMERCE.url}/Pedido/getCategoriaWeb?prmintCentro=${Centro}`,
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_ECOMMERCE) })
		 .pipe(map(data => data));
    }

    public registerUser(User) {
		return this.httpClient.post(`${environment.api.WS_IT.url}/RegisterClients/Guardar`, User,		  
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT) })
		 .pipe(map(data => data));
    }

    forgotPassword(Email: string/*, Dni: string*/) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/RecoveryMethod/RecoveryPass`, 
            {
                Email: Email
                // ,Dni: Dni
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
    }

    forgotPasswordDanper(Email: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ForgotPassword`, 
            {
                login: Email
                // ,Dni: Dni
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
    }
    

    changePassword(passwordActual:string, passwordNueva: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/Seguridad/ChangePassword`, 
            {
                passwordActual: passwordActual,
                passwordNueva: passwordNueva
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
    }

    resetPassword(Pass: string, RePass: string, Token: string) {
        return this.httpClient.post(`${environment.api.WS_IT.url}/RecoveryMethod/SetNewPassword`, 
            {
                password1: Pass,
                password2: RePass,
                token: Token
            }, {
                headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT)
            }
        )
    }

    isLoggedIn() {
        let jwt: string = this.storageService.get(StorageKeyEnum.JWT_AUTHORIZATION);
        if (jwt != null) {
            this.jwtService.load(jwt);
            return this.jwtService.isValid();
        } else {
            return false;
        }
    }

    redirectToLogin() {
        this.router.navigate(['/signup']);
    }
    redirectToHome() {
        this.router.navigate(['/Home']);
    }

    redirectToMain() {
        this.router.navigate(['/']);
        //this.router.navigate(['/welcome']);
    }

    redirectToNotAllowed() {
        this.router.navigate(['/error/not-allowed']);
    }

    validateSuccessCart(Cart) {
		return this.httpClient.get(`${environment.api.WS_ECOMMERCE.url}/Pedido/EC_ValidateSuccessCart?prmintCart=${Cart}`,
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_ECOMMERCE) })
		 .pipe(map(data => data));
    }

    validateStatusCart(Cart) {
		return this.httpClient.get(`${environment.api.WS_ECOMMERCE.url}/Pedido/EC_ValidateStatusCart?prmintCart=${Cart}`,
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_ECOMMERCE) })
		 .pipe(map(data => data));
    }

    getCurrentCart() {
		return this.httpClient.post(`${environment.api.WS_ECOMMERCE.url}/Pedido/EC_GetCurrentCart`,{},
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_ECOMMERCE) })
		 .pipe(map(data => data));
    }

    getLastSalesOrder() {
		return this.httpClient.post(`${environment.api.WS_ECOMMERCE.url}/Pedido/EC_GetLastSalesOrder`,{},
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_ECOMMERCE) })
		 .pipe(map(data => data));
    }
}